import React from 'react';
import { RequestProvider } from '@shootsta/common-react';
import IdentityLoginComponent from '../components/IdentityLogin';
import { getOrgNameFromAliasApi } from '../api';

const IdentityLogin = () => (
  <RequestProvider
    requests={{
      getOrganisationNameFromAlias: getOrgNameFromAliasApi()
    }}
  >
    {({ getOrganisationNameFromAlias }) => (
      <IdentityLoginComponent
        getOrganisationNameFromAlias={getOrganisationNameFromAlias}
      />
    )}
  </RequestProvider>
);

export default IdentityLogin;
