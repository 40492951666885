import React, { memo } from 'react';
import { Icon, Text } from '@shootsta/common-react';
import SVG from 'react-inlinesvg';
import maintenance from '../../../assets/images/maintenance.svg';
import './styles/_maintenance_content.scss';

type MaintenanceScreenProps = {
  className?: string;
};

function reloadPage() {
  window.location.reload();
}

function MaintenanceScreenContent({ className }: MaintenanceScreenProps) {
  return (
    <div className="maintenance-page">
      <div className="maintenance-page__inner">
        <SVG
          cacheRequests
          className="maintenance-page__icon"
          src={maintenance}
          uniquifyIDs
        />
        <Text className="maintenance-page__header" noMargin="top" heading3>
          {"We'll Be Back Soon"}
        </Text>
        <Text subtitle noMargin="bottom">
          Sorry for the inconvenience but we&apos;re performing some maintenance
          at the moment.
          <br />
          We&apos;re praparing to serve you better, Thank you.
        </Text>
      </div>
    </div>
  );
}

export default memo<MaintenanceScreenProps>(MaintenanceScreenContent);
