import React from 'react';
import { ChipListV2, Chip, Text, Checkbox } from '@shootsta/common-react';
import { CONTENT_TYPES } from '@shootsta/common-domain-data';

type Step3Props = {
  contentTypes: Array<string>;
  setContentTypes: any;
};

const Step3 = ({ contentTypes, setContentTypes }: Step3Props) => {
  const onClick = (contentType: any) => {
    setContentTypes(contentType.selection);
  };
  return (
    <div className="wizard-body__finish-content">
      <div className="wizard-body__finish-content__item">
        <Text label darkTheme>
          What type of content are you interested in creating?
        </Text>
        <ChipListV2
          onChange={onClick}
          value={contentTypes}
          suffix="addfat"
          suffixAfterSelect="success"
          multiple
        >
          {CONTENT_TYPES.map((type) => (
            <Chip key={type} value={type}>
              {type}
            </Chip>
          ))}
        </ChipListV2>
      </div>
      <Text label darkTheme>
        Your data is secure.
      </Text>
      <div className="wizard-body__finish-content__terms-condition">
        <Text
          label
          className="wizard-body__finish-content__terms-condition__text"
        >
          AI Compose does not share your data with third parties or use it for
          training public AI models. AI Compose uses the provided information to
          improve your personalised experience, including generating content for
          your project briefs and &nbsp;
          <a
            href="/article-to-brief"
            className="wizard-body__finish-content__terms-condition__text__link"
            target="_blank"
          >
            recommending articles.
          </a>
        </Text>
      </div>
    </div>
  );
};

export default Step3;
