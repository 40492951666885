import moment from 'moment';
import _sortedUniqBy from 'lodash.sorteduniqby';
import _isEmpty from 'lodash.isempty';
import type { Notification } from '../types/notification';

export const sortLatestCreated = (a: Notification, b: Notification) =>
  moment(b.createdAt).diff(moment(a.createdAt), 'milliseconds');

export const filterNotEmpty = (obj: any) => !_isEmpty(obj);

export const sortDedupNotifications = (notifications: Notification[]) =>
  _sortedUniqBy(
    notifications.filter(filterNotEmpty).sort(sortLatestCreated),
    'notificationId'
  );
