import { FormModal, Icon, Text } from '@shootsta/common-react';
import { openHubSpotConversationsWidget } from '../../../hubSpot';

import '../styles/_payment-failed-modal.scss';

const PaymentFailedModal = ({
  visible,
  onClose
}: {
  visible: boolean;
  onClose?: Function;
}) => {
  return (
    <FormModal
      onClose={() => onClose(false)}
      visible={visible}
      onSave={openHubSpotConversationsWidget}
      saveText="Get Help"
    >
      <div className="payment-failed-modal">
        <Icon name="alert" color="redApple" size="36" />
        <Text className="payment-failed-modal__title" heading2>
          Oops, something didn’t work with your payment.
        </Text>
        <Text className="payment-failed-modal__subtitle">
          If you’re still having trouble, we’re here to help! Reach out to our
          support team via the Live Chat or contact your Account Manager.
        </Text>
      </div>
    </FormModal>
  );
};

export default PaymentFailedModal;
