import { authLocalStore, credentials } from '@shootsta/client-auth';

export default async function identityGhostUser(
  ghostStart: any,
  ghost: string
) {
  const { email, origURL, targetURL = '/' } = JSON.parse(atob(ghost));

  if (!email || !origURL) {
    throw new Error('Required ghost parameters are not provided.');
  }

  const { error, data } = await ghostStart({ email });

  if (!data || !data.ghostStart || !data.ghostStart.user || error) {
    throw new Error('Ghost failed.');
  }

  const originalCredentials = credentials.get();

  const ghostCredentials = {
    auth: true,
    ghost: true,
    token: data.ghostStart.token,
    user: data.ghostStart.user,
    targetURL: '/',
    original: originalCredentials,
    origURL
  } as const;

  authLocalStore.save(ghostCredentials);

  window.location.replace(targetURL || '/');
}
