import React from 'react';
import { AuthorisationConsumer } from '@shootsta/common-react';
import SwitchOrganisationModalComponent from '../components/SwitchOrganisationModal';

function organisationsToSwitch(
  organisationMemberships: any,
  selectedOrganisation: any
) {
  const filteredOrganisationMemberships = organisationMemberships.filter(
    ({ organisation }) =>
      organisation.organisationId !== selectedOrganisation.organisationId
  );

  return filteredOrganisationMemberships.map(
    ({ organisation }) => organisation
  );
}

function SwitchOrganisationModal(props: any) {
  return (
    <AuthorisationConsumer>
      {({
        updateSelectedOrganisation,
        organisationMemberships,
        selectedOrganisation
      }) => (
        <SwitchOrganisationModalComponent
          updateSelectedOrganisation={updateSelectedOrganisation}
          organisations={organisationsToSwitch(
            organisationMemberships,
            selectedOrganisation
          )}
          selectedOrganisation={selectedOrganisation}
          {...props}
        />
      )}
    </AuthorisationConsumer>
  );
}

export default SwitchOrganisationModal;
