import { notify } from '@shootsta/common-react';

declare global {
  interface Window {
    HubSpotConversations?: {
      widget: {
        load: () => void;
        open: () => void;
        refresh: () => void;
      };
    };
    hsConversationsOnReady?: Array<() => void>;
  }
}

type sendHubSpotIdentifyProps = {
  onConversationsAPIReady: () => void;
};

const handleUnavailableWidget = () => {
  notify({
    content: 'Our chat service is currently unavailable',
    type: 'error'
  });
};

export const openHubSpotConversationsWidget = () => {
  if (window.HubSpotConversations && window.HubSpotConversations.widget) {
    window.HubSpotConversations?.widget?.load();
    setTimeout(() => {
      window.HubSpotConversations?.widget?.open();
    }, 1000);
  } else {
    handleUnavailableWidget();
  }
};

export const sendHubSpotIdentify = (props: sendHubSpotIdentifyProps) => {
  const { onConversationsAPIReady } = props;
  if (window.HubSpotConversations) {
    onConversationsAPIReady();
  } else {
    window.hsConversationsOnReady = [onConversationsAPIReady];
  }
};

export const refreshHubSpotConversationsWidget = () => {
  if (window.HubSpotConversations && window.HubSpotConversations.widget) {
    window.HubSpotConversations.widget.refresh();
  }
};
