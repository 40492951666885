import {
  FormModal,
  Icon,
  Text,
  Button
} from '@shootsta/common-react';

import '../styles/_payment-successful-modal.scss';

const PaymentSuccessfulModal = ({
  visible,
  onClose,
  orderId,
  onViewInvoice
}: {
  visible: boolean;
  onClose: (args: boolean) => void;
  orderId: string;
  onViewInvoice: (orderId: string) => void;
}) => {
  const onCloseHandler = () => onClose(false);
  return (
    <FormModal
      visible={visible}
      showExtraButtons
      onSave={onCloseHandler}
      onClose={onCloseHandler}
      showCancelBtn={false}
      saveText="Close"
      footerExtraButtons={
        <Button onClick={() => onViewInvoice(orderId)} type="TERTIARY">
          View Invoice
        </Button>
      }
    >
      <div className="payment-successful-modal">
        <div className="payment-successful-modal__heading">
          <Icon name="checkedFilled" color="monkey" size="40" />
          <Text className="payment-successful-modal__heading__title" heading2>
            Payment successful!
          </Text>
          <Text className="payment-successful-modal__heading__subtitle">
            Your payment was completed successfully! Feel free to contact us if
            you have any questions.
          </Text>
        </div>
      </div>
    </FormModal>
  );
};

export default PaymentSuccessfulModal;
