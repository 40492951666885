import { gql } from 'apollo-boost';

const LOGIN_WORKSPACE_QUERY = gql`
  mutation (
    $authorizationCode: String!
    $alias: String!
    $fingerprint: String
  ) {
    workspaceLogin(
      authorizationCode: $authorizationCode
      alias: $alias
      fingerprint: $fingerprint
    ) {
      auth
      token
      user {
        firstName
        lastName
        userId
        email
        imageUrl
        preferences
        isAvailable
        isAnonymous
        initialSetup
      }
    }
  }
`;

export default () => ({
  mutation: LOGIN_WORKSPACE_QUERY,
  module: 'identity',
  buildVariables: ({
    authorizationCode,
    alias,
    fingerprint
  }: {
    authorizationCode: string;
    alias: string;
    fingerprint: string;
  }) => ({ authorizationCode, alias, fingerprint })
});
