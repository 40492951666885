import React from 'react';
import { AuthorisationConsumer } from '@shootsta/common-react';
import { withRouter } from 'react-router-dom';
import AdminModeContext from '../../../../../contexts/AdminMode';
import UserSummaryComponent from '../components/UserSummary';

function UserSummary(props: any) {
  return (
    <AuthorisationConsumer>
      {({ organisationMemberships, isShootstaEditor }) => (
        <AdminModeContext.Consumer>
          {(adminMode) => (
            <UserSummaryComponent
              isAdminMode={adminMode}
              organisationMemberships={organisationMemberships}
              isShootstaEditor={isShootstaEditor}
              {...props}
            />
          )}
        </AdminModeContext.Consumer>
      )}
    </AuthorisationConsumer>
  );
}

export default withRouter(UserSummary);
