import gql from 'graphql-tag';

const getUserRecentElevatesQuery = gql`
  query GetFilteredVideos(
    $organisationId: String!
    $filterOptions: FilterOptions
  ) {
    getFilteredVideos(
      organisationId: $organisationId
      filterOptions: $filterOptions
    ) {
      count
      videos {
        videoId
        assetId
        status
        recipientName
        recipientCompany
        templateName
        thumbnailUrl
        splashLink
        updatedAt
        archivedAt
        platformVideoId
        outputId
      }
    }
  }
`;

const getUserRecentElevates = () => ({
  query: getUserRecentElevatesQuery,
  module: 'pitch',
  ignoreCache: true,
  buildVariables: ({ organisationId }: any) => ({
    organisationId
  })
});

export default getUserRecentElevates;
