import React from 'react';
import { Subscription } from 'react-apollo';
import { notificationCreatedSubscription } from '../api';

type Props = {
  requests: any;
  children: any;
};

const RemoteNotifications = ({ requests, children }: Props) => (
  <Subscription {...notificationCreatedSubscription()}>
    {({ data }) =>
      children({
        requests,
        newNotification: data
      })
    }
  </Subscription>
);

export default RemoteNotifications;
