import React from 'react';
import { credentials } from '@shootsta/client-auth';
import { RequestProvider } from '@shootsta/common-react';
import { withRouter } from 'react-router-dom';
import type { RouterHistory } from 'react-router-dom';
import ChooseWorkspaceComponent from '../components/ChooseWorkspace';
import { getUserOrganisationsApi } from '../api';

const ChooseWorkspace = ({ history }: { history: RouterHistory }) => (
  <RequestProvider
    requests={{ getUserOrganisations: getUserOrganisationsApi() }}
  >
    {({ getUserOrganisations }) => {
      const { user } = credentials.get();

      return (
        <ChooseWorkspaceComponent
          history={history}
          email={user.email}
          getUserOrganisations={getUserOrganisations}
        />
      );
    }}
  </RequestProvider>
);

export default withRouter(ChooseWorkspace);
