import React from 'react';
import '../styles/_login-form.scss';
import { Link } from '@shootsta/common-react';

const LOGON_HELP_ARTICLE =
  'https://help.shootsta.com/en/articles/4547921-shootsta-hub-workspaces';

type LoginHelpProps = {
  workspaceLinkText?: string;
  history: any;
};

function goToLogonHelpArticle() {
  window.open(LOGON_HELP_ARTICLE, '_blank');
}

function goToWorkspaceCode(history: any) {
  history.push('/get-workspaces');
}

function LoginHelp({ history, workspaceLinkText }: LoginHelpProps) {
  return (
    <div className="login-form__body__account">
      <div className="login-form__body__help-link">
        {workspaceLinkText || 'Looking for a different workspace?'}{' '}
        <Link onClick={() => goToWorkspaceCode(history)}>
          Find your workspace.
        </Link>
      </div>
      <div className="login-form__body__help-link">
        {'Still need help? '}
        <Link onClick={goToLogonHelpArticle}>Check this out.</Link>
      </div>
    </div>
  );
}

export default LoginHelp;
