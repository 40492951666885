import { gql } from 'apollo-boost';

const notificationCreated = gql`
  subscription NotificationCreated {
    notificationCreated {
      notificationId
      sourceUserId
      userId
      type
      link
      read
      createdAt
      updatedAt
      resource
      sourceUser {
        userId
        imageUrl
        firstName
        lastName
      }
    }
  }
`;

export default () => ({
  subscription: notificationCreated,
  fetchPolicy: 'no-cache'
});
