import React, { Component } from 'react';
import { Error } from '@shootsta/common-react';
import type { ReactNode } from 'react';

type ErrorBoundaryState = {
  error?: Error;
};

type ErrorBoundaryProps = {
  children: Node;
};

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {};
  }

  componentDidCatch(error: Error) {
    this.setState({ error });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (!error) {
      return children;
    }

    return <Error />;
  }
}
