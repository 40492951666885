import React, { memo } from 'react';
import type { ReactNode } from 'react';
import Background from '../Background';
import '../../styles/_login.scss';

type WorkspaceWrapperProps = {
  children: Node;
  backgroundType: 'person' | 'lost' | 'create';
};

const WorkspaceWrapper = ({
  children,
  backgroundType
}: WorkspaceWrapperProps) => (
  <div className="login">
    <div className="login__container" data-cy="workspace-container">
      <div className="login__left-content">
        <div>{children}</div>
      </div>
      <Background type={backgroundType} />
    </div>
  </div>
);

export default memo<WorkspaceWrapperProps>(WorkspaceWrapper);
