import { memo } from 'react';
import { credentials } from '@shootsta/client-auth';
import _omit from 'lodash.omit';
import { getBaseDomain } from '../../../../utils';

const baseDomain = getBaseDomain();

type BookingsLinkProps = {
  selectedOrganisation: any;
};

function BookingsLink({
  selectedOrganisation: { package: orgPackage, ...rest }
}: BookingsLinkProps) {
  const isProd = baseDomain === 'app.shootsta.com';

  const organisation = {
    ..._omit(rest, ['customAddonCosts']),
    package: { ..._omit(orgPackage, ['addonCosts']) }
  } as const;

  const token = btoa(
    JSON.stringify({
      organisation,
      ...credentials.get()
    })
  );

  window.location.href = `https://bookings${isProd ? '' : '-test'}.shootsta.com/sso/hub3?token=${token}`;
  return null;
}

export default memo<BookingsLinkProps>(BookingsLink);
