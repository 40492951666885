export default function (versions: Array<any>) {
  return (
    versions.reduce(
      (latestVersion, version) =>
        version.versionNumber > latestVersion.versionNumber
          ? version
          : latestVersion,
      versions[0]
    ) || {}
  );
}
