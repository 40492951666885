function importRetry(
  fn: any,
  //$FlowFixMe
  retries = 5,
  //$FlowFixMe
  interval = 1000
): Promise<any> {
  return new Promise(
    (
      resolve: (result: Promise<never>) => void,
      reject: (error?: any) => void
    ) => {
      fn()
        .then(resolve)
        .catch((error) => {
          setTimeout(() => {
            if (retries === 0) {
              reject(error);
              return;
            }
            importRetry(fn, retries - 1, interval).then(resolve, reject);
          }, interval);
        });
    }
  );
}

export default importRetry;
