import React from 'react';
import { Text } from '@shootsta/common-react';
import { buildNotificationString } from '../../../../../utils';
import getUpdatedLink from './getUpdatedLink';

type Props = {
  notificationId: string;
  fullName: string;
  link: string;
  read: boolean;
  type: string;
  displayedCreatedAt: string;
  className: string;
  onLinkSelect: any;
  resource?: any;
};

export default function NotificationItem(props: Props) {
  const {
    notificationId,
    fullName,
    link,
    read,
    type,
    displayedCreatedAt,
    className,
    onLinkSelect,
    resource
  } = props;

  return (
    <a
      className={`${className}__item`}
      target="_blank"
      href={getUpdatedLink(link)}
      rel="noopener noreferrer"
      onClick={onLinkSelect(notificationId)}
    >
      <div className={`${className}__item__content`}>
        <Text noMargin highlighted={!read}>
          {buildNotificationString({
            notificationType: type,
            fullName,
            resource
          })}
        </Text>
        <Text noMargin timestamp>
          {displayedCreatedAt}
        </Text>
      </div>
      {!read && <span className={`${className}__item__unread`} />}
    </a>
  );
}
