const VIDEO_STATUSES = {
  DRAFT: 'DRAFT',
  REQUIRES_ACTION: 'REQUIRES_ACTION',
  ON_HOLD: 'ON_HOLD',
  COMPLETE: 'COMPLETE',
  IN_PROGRESS: 'IN_PROGRESS'
} as const;

export default function getStatusForVideo(status: string) {
  if (status === 'DRAFT') {
    return VIDEO_STATUSES.DRAFT;
  }

  if (
    ['PENDING_REVIEW', 'PENDING_SOCIAL_REVIEW', 'COST_REVIEW'].includes(status)
  ) {
    return VIDEO_STATUSES.REQUIRES_ACTION;
  }

  if (status === 'APPROVED') {
    return VIDEO_STATUSES.COMPLETE;
  }

  if (status === 'ON_HOLD') {
    return VIDEO_STATUSES.ON_HOLD;
  }

  return VIDEO_STATUSES.IN_PROGRESS;
}
