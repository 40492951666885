import gql from 'graphql-tag';

const getUser = gql`
  query getUser($userId: String!) {
    getUser(userId: $userId) {
      userId
      firstName
      lastName
      email
      phoneNumber
      workNumber
      linkedInUrl
      officeLocation
      department
      jobTitle
      imageUrl
      region
      role
      timezone
      isEmailVerified
      location {
        addressId
        unit
        country
        street
        citySuburb
        postCode
        state
      }
      ageRange
      topics
      contentTypes
      biOnboardingComplete
      industry
    }
  }
`;

export default (userId?: string | null) => ({
  query: getUser,
  module: 'users',
  ignoreCache: true,
  buildVariables: () => ({
    userId
  })
});
