import React, { memo } from 'react';
import MediaQuery from 'react-responsive';

import { ReactComponent as Wave } from '../../../../../assets/images/wave.svg';
import { ReactComponent as Person } from '../../../../../assets/images/person.svg';
import { ReactComponent as Create } from '../../../../../assets/images/create-media.svg';
import { ReactComponent as Auth } from '../../../../../assets/images/forgot.svg';
import { ReactComponent as Lost } from '../../../../../assets/images/lost.svg';

type BackgroundProps = {
  type: string;
};

function getBackgroundImage(type: string) {
  switch (type) {
    case 'person':
      return Person;

    case 'create':
      return Create;

    case 'auth':
      return Auth;

    case 'lost':
      return Lost;

    default:
      return Auth;
  }
}

function Background({ type }: BackgroundProps) {
  const BackgroundImage = getBackgroundImage(type);

  return (
    <MediaQuery minWidth={640}>
      <div className="login__side-panel">
        <Wave />
        <Wave />
        <div className="login__side-panel__inner">
          <BackgroundImage />
        </div>
      </div>
    </MediaQuery>
  );
}

export default memo<BackgroundProps>(Background);
