/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { Loader, withFlagsConsumer } from '@shootsta/common-react';
import { credentials } from '@shootsta/client-auth';
import type { RouterHistory } from 'react-router-dom';
import HowToCarousel from './HowToCarousel';
import RecentCarousel from './RecentCarousel';
import ProductBanner from './ProductBanner';
import QuickActions from './QuickActions';
import ArticleBanner from './ArticleBanner';
import ProductBannerLarge from './ProductBannerLarge';
import { PRODUCT_BANNERS, HOW_TO_CARDS, RECENT_CAROUSELS } from '../constants';
import config from '../../../../config';

let mountTimer: any;

type UserHomepageContainerProps = {
  organisation: any;
  getUserRecentActivity: any;
  getUserRecentElevates: any;
  getAssetThumbnail: any;
  shootstaAI: boolean;
  getBannerArticles: any;
  history: RouterHistory;
  globalStore: any;
  flags: any;
  logEvent: any;
};

function UserHomepage({
  organisation,
  getUserRecentActivity,
  getUserRecentElevates,
  shootstaAI,
  getBannerArticles,
  getAssetThumbnail,
  history,
  globalStore,
  flags,
  logEvent
}: UserHomepageContainerProps) {
  const cachedState = globalStore.getObjectForKey('/home');
  const { enableBiTool } = flags;

  const [castSub, setCastSub] = useState(cachedState.castSub || false);
  const [castSubExpired, setCastSubExpired] = useState(
    cachedState.castSubExpired || false
  );
  const [elevateSub, setElevateSub] = useState(cachedState.evelateSub || false);
  const [elevateSubExpired, setElevateSubExpired] = useState(
    cachedState.elevateSubExpiredfalse
  );
  const [isAdmin, setIsAdmin] = useState(cachedState.isAdmin || false);
  const [loadingComplete, setLoadingComplete] = useState(
    cachedState.loadingComplete || false
  );
  const [noSubs, setNoSubs] = useState(false);
  const [productSubs, setProductSubs] = useState(cachedState.productSubs || []);
  const [proSub, setProSub] = useState(cachedState.proSub || false);
  const [proSubExpired, setProSubExpired] = useState(
    cachedState.proSubExpired || false
  );
  const [recentCast, setRecentCast] = useState(cachedState.recentCast || []);
  const [recentElevate, setRecentElevate] = useState(
    cachedState.recentElevate || []
  );
  const [recentPro, setRecentPro] = useState(cachedState.recentPro || []);
  const pageLoadLogged = useRef(false);

  const getSubExpiryStatus = (expiryDate: string) => {
    const now = moment();
    const endDate = moment(expiryDate);
    const subExpired = now.isAfter(endDate);
    const readableEndDate = endDate.format('Do MMMM YYYY');
    return {
      subExpired,
      readableEndDate
    };
  };

  const enableBiToolShootstaAI = shootstaAI && enableBiTool;

  const getOrgSubs = async () => {
    const {
      organisationProduct,
      permissions,
      package: orgProPackage
    } = organisation;
    const tempProductSubs = [];

    setIsAdmin(permissions.includes('MANAGE_ORGANISATION'));

    // DETERMINE IF ORG HAS A PRO SUBSCRIPTION
    if (orgProPackage) {
      const { subEnd } = orgProPackage;
      const status = subEnd
        ? getSubExpiryStatus(subEnd)
        : {
            subExpired: false,
            readableEndDate: null
          };
      const { subExpired, readableEndDate } = status;
      tempProductSubs.push({
        productName: 'Pro',
        subEndDate: readableEndDate,
        subExpired
      });
    }

    // DETERMINE IF ORG HAS SUBSCRIPTIONS FOR: CAST, ELEVATE, etc..
    if (!organisationProduct.length) {
      if (tempProductSubs.length) setProductSubs(tempProductSubs);
      return;
    }

    organisationProduct.forEach((item, i) => {
      const { product: orgProduct, expiryDate, trial } = item;
      const { shortName } = orgProduct;
      const status = expiryDate
        ? getSubExpiryStatus(expiryDate)
        : {
            subExpired: false,
            readableEndDate: null,
            trial: null
          };
      const { subExpired, readableEndDate } = status;
      tempProductSubs.push({
        productName: shortName,
        subEndDate: readableEndDate,
        subExpired,
        trial
      });
    });

    setProductSubs(tempProductSubs);
  };

  const getAssetThumbnails = async (assetOutputs: Array<any>) => {
    const tempAssetIds: Array<any> = [];

    assetOutputs.forEach((output) => {
      const { video } = output;
      const { clips } = video;
      if (!clips) return;
      const clipId = clips[0];
      if (!clipId) return;
      tempAssetIds.push(clipId.assetId);
    });

    if (!tempAssetIds.length) return assetOutputs;

    const queryParams = {
      assetIds: tempAssetIds
    } as const;

    const { data, error } = await getAssetThumbnail(queryParams);
    if (!data || error) return assetOutputs;

    const { getAssets } = data;
    if (!getAssets) return assetOutputs;

    await assetOutputs.forEach((output, i) => {
      const { video } = output;
      const { clips } = video;
      if (!clips) return;
      const clipId = clips[0];
      if (!clipId || !clipId.assetId) return;

      const clipAssetId = clipId.assetId;

      getAssets.forEach((asset) => {
        const { assetFiles, assetId: thumbAssetId } = asset;
        const { THUMBNAIL } = assetFiles;
        if (!THUMBNAIL) return;

        const { sourceUrl } = THUMBNAIL;
        if (!sourceUrl) return;

        if (thumbAssetId === clipAssetId)
          assetOutputs[i].assetThumbnailUrl = sourceUrl;
      });
    });

    return assetOutputs;
  };

  const getRecent = async (product: string) => {
    const { organisationId } = organisation;

    const queryParams = {
      limit: 12,
      offset: 0,
      sortBy: 'updatedAtDesc',
      organisationId,
      product
    } as const;

    const { data, error } =
      product === 'PITCH'
        ? await getUserRecentElevates({ ...queryParams })
        : await getUserRecentActivity({ ...queryParams });

    if (!data || error) {
      setLoadingComplete(true);
      return;
    }

    const { getFilteredOutputs, getFilteredVideos } = data;

    if (!getFilteredOutputs && !getFilteredVideos) {
      setLoadingComplete(true);
      return;
    }

    const pitchOutputs = getFilteredVideos
      ? getFilteredVideos.videos
          .filter((video) => video.status === 'COMPLETED')
          .map((video) => ({
            outputId: video.outputId,
            videoId: video.videoId,
            video: {
              clips: [video.assetId],
              name: `${video.recipientName} - ${video.recipientCompany}`,
              ...video
            },
            status: {
              name: 'APPROVED'
            }
          }))
      : [];

    const outputs = getFilteredOutputs
      ? getFilteredOutputs.outputs
      : pitchOutputs;
    if (!outputs || !outputs.length) {
      setLoadingComplete(true);
      return;
    }

    const outputsWithThumbnails = await getAssetThumbnails(outputs);

    if (!outputsWithThumbnails.length) {
      setLoadingComplete(true);
      return;
    }

    if (product === 'PRO') setRecentPro(outputsWithThumbnails);
    if (product === 'CAST') setRecentCast(outputsWithThumbnails);
    if (!config.HIDE_FEATURES.elevate) {
      if (product === 'PITCH') setRecentElevate(outputsWithThumbnails);
    }

    mountTimer = await setTimeout(() => {
      setLoadingComplete(true);
    }, 300);
  };

  useEffect(() => {
    if (
      logEvent &&
      organisation.organisationId &&
      enableBiToolShootstaAI &&
      !pageLoadLogged.current
    ) {
      logEvent({
        organisationId: organisation.organisationId,
        type: 'USER_HOMEPAGE_PAGELOAD'
      });
      pageLoadLogged.current = true;
    }
  }, [logEvent, organisation, enableBiToolShootstaAI]);

  useEffect(() => {
    if (!productSubs.length) {
      setNoSubs(true);
      setLoadingComplete(true);
      return;
    }

    setNoSubs(false);
    // setLoadingComplete(false);

    productSubs.forEach((product) => {
      const { productName, subExpired } = product;

      if (productName.includes('Pro')) {
        setProSub(true);
        setProSubExpired(subExpired);
        getRecent('PRO');
      }
      if (productName.includes('Cast')) {
        setCastSub(true);
        setCastSubExpired(subExpired);
        getRecent('CAST');
      }
      if (!config.HIDE_FEATURES.elevate) {
        if (productName.includes('Elevate')) {
          setElevateSub(true);
          setElevateSubExpired(subExpired);
          getRecent('PITCH');
        }
      }
    });
  }, [productSubs]);

  useEffect(() => {
    getOrgSubs();
    return () => {
      clearTimeout(mountTimer);
    };
  }, []);

  useEffect(() => {
    globalStore.updateObjectForKey('/home', {
      castSub,
      castSubExpired,
      elevateSub,
      elevateSubExpired,
      isAdmin,
      loadingComplete,
      noSubs,
      productSubs,
      proSub,
      proSubExpired,
      recentCast,
      recentElevate,
      recentPro
    });
  }, [
    castSub,
    castSubExpired,
    elevateSub,
    elevateSubExpired,
    isAdmin,
    loadingComplete,
    noSubs,
    productSubs,
    proSub,
    proSubExpired,
    recentCast,
    recentElevate,
    recentPro
  ]);

  const itemClick = (ctaPath: string) => {
    history.push(ctaPath);
  };

  const itemClickExternal = (ctaPath: string) => {
    window.open(ctaPath, '_blank');
  };

  // IF ORG HAS PRO+CAST SUBSCRIPTIONS BUT NO VIDEOS
  const hasProCastSubWithNoProCastVideos =
    proSub && castSub && !recentPro.length && !recentCast.length;

  // IF ORG HAS PRO+CAST SUBSCRIPTIONS AND ONE HAS NO VIDEOS
  const hasOnlyProCastSubWithOneHasNoVideos =
    proSub &&
    castSub &&
    !elevateSub &&
    ((recentPro.length && !recentCast.length) ||
      (!recentPro.length && recentCast.length));

  // IF ORG HAS ALL SUBSCRIPTIONS BUT NO VIDEOS
  const hasAllSubsWithNoVideos =
    proSub &&
    castSub &&
    elevateSub &&
    !recentPro.length &&
    !recentCast.length &&
    !recentElevate.length;

  // IF ORG HAS CAST SUBSCRIPTION ONLY AND NO VIDEOS
  const hasOnlyCastSubWithNoCastVideos =
    !proSub && castSub && !elevateSub && !recentCast.length;

  return (
    <div className="user-homepage-container">
      {enableBiToolShootstaAI ? (
        <>
          <QuickActions />
          <ArticleBanner
            getBannerArticles={getBannerArticles}
            history={history}
            organisationId={organisation.organisationId}
          />
        </>
      ) : (
        <HowToCarousel carouselCards={HOW_TO_CARDS} />
      )}

      {loadingComplete ? (
        <>
          {/* IF ORG HAS PRO SUB —-> SHOW MOST RECENT PROJECTS FOR USER OR EMPTY STATE BANNER */}
          {proSub && !proSubExpired ? (
            recentPro.length ? (
              <RecentCarousel
                productName={RECENT_CAROUSELS.PRO.PRODUCT_NAME}
                carouselTitle={RECENT_CAROUSELS.PRO.CAROUSEL_TITLE}
                carouselCards={recentPro}
                carouselTabPath={RECENT_CAROUSELS.PRO.DEFAULT_PATH}
                itemClick={(path) => itemClick(path)}
              />
            ) : recentCast.length ? (
              <ProductBanner
                heading={PRODUCT_BANNERS.PRO.HEADING}
                bannerImage={PRODUCT_BANNERS.PRO.IMAGE}
                ctaText={PRODUCT_BANNERS.PRO.CTA_TEXT}
                ctaDefaultPath={PRODUCT_BANNERS.PRO.CTA_DEFAULT_PATH}
                ctaClick={(ctaPath) => itemClick(ctaPath)}
              />
            ) : null
          ) : proSubExpired ? (
            <ProductBanner
              heading={
                isAdmin
                  ? PRODUCT_BANNERS.PRO_EXPIRED.HEADING_ADMIN
                  : PRODUCT_BANNERS.PRO_EXPIRED.HEADING
              }
              bannerImage={PRODUCT_BANNERS.PRO_EXPIRED.IMAGE}
              ctaText={PRODUCT_BANNERS.PRO_EXPIRED.CTA_TEXT}
              ctaDefaultPath={PRODUCT_BANNERS.PRO_EXPIRED.CTA_DEFAULT_PATH}
              ctaClick={(ctaPath) => itemClick(ctaPath)}
            />
          ) : null}

          {/* IF ORG HAS CAST SUB —-> SHOW MOST RECENT CASTS FOR USER OR EMPTY STATE BANNER */}
          {castSub && !castSubExpired ? (
            recentCast.length ? (
              <RecentCarousel
                productName={RECENT_CAROUSELS.CAST.PRODUCT_NAME}
                carouselTitle={RECENT_CAROUSELS.CAST.CAROUSEL_TITLE}
                carouselCards={recentCast}
                carouselTabPath={RECENT_CAROUSELS.CAST.DEFAULT_PATH}
                itemClick={(path) => itemClick(path)}
              />
            ) : recentPro.length ? (
              <ProductBanner
                heading={PRODUCT_BANNERS.CAST.HEADING}
                bannerImage={PRODUCT_BANNERS.CAST.IMAGE}
                ctaText={PRODUCT_BANNERS.CAST.CTA_TEXT}
                ctaDefaultPath={PRODUCT_BANNERS.CAST.CTA_DEFAULT_PATH}
                ctaClick={(ctaPath) => itemClick(ctaPath)}
              />
            ) : null
          ) : castSubExpired ? (
            <ProductBanner
              heading={
                isAdmin
                  ? PRODUCT_BANNERS.CAST_EXPIRED.HEADING_ADMIN
                  : PRODUCT_BANNERS.CAST_EXPIRED.HEADING
              }
              bannerImage={PRODUCT_BANNERS.CAST_EXPIRED.IMAGE}
              ctaText={PRODUCT_BANNERS.CAST_EXPIRED.CTA_TEXT}
              ctaDefaultPath={PRODUCT_BANNERS.CAST_EXPIRED.CTA_DEFAULT_PATH}
              ctaClick={(ctaPath) => itemClick(ctaPath)}
            />
          ) : null}

          {/* IF ORG HAS PRO+CAST SUBS BUT NO VIDEOS --> AGGREGATE PRO+CAST BANNERS INTO ONE */}
          {hasProCastSubWithNoProCastVideos ? (
            <ProductBanner
              heading={PRODUCT_BANNERS.PRO_CAST_AGGREGATED.HEADING}
              ctaList={PRODUCT_BANNERS.PRO_CAST_AGGREGATED.CTA_LIST}
              ctaListButtonText={
                PRODUCT_BANNERS.PRO_CAST_AGGREGATED.CTA_LIST_BUTTON_TEXT
              }
              ctaDefaultPath={
                PRODUCT_BANNERS.PRO_CAST_AGGREGATED.CTA_DEFAULT_PATH
              }
              ctaClick={(ctaPath) => itemClick(ctaPath)}
            />
          ) : null}

          {hasProCastSubWithNoProCastVideos ||
          hasOnlyProCastSubWithOneHasNoVideos ||
          hasAllSubsWithNoVideos ||
          hasOnlyCastSubWithNoCastVideos ? (
            <ProductBannerLarge
              heading={PRODUCT_BANNERS.NO_SUBS.HEADING}
              bannerImage={PRODUCT_BANNERS.NO_SUBS.IMAGE}
              ctaText={PRODUCT_BANNERS.NO_SUBS.CTA_TEXT}
              ctaClick={() =>
                itemClick(PRODUCT_BANNERS.NO_SUBS.CTA_DEFAULT_PATH)
              }
            />
          ) : null}

          {/* IF ORG HAS RECENT ELEVATES —-> SHOW MOST RECENT VIDEOS OR EMPTY STATE BANNER */}
          {!config.HIDE_FEATURES.elevate && !elevateSubExpired ? (
            recentElevate.length ? (
              <RecentCarousel
                productName={RECENT_CAROUSELS.ELEVATE.PRODUCT_NAME}
                carouselTitle={RECENT_CAROUSELS.ELEVATE.CAROUSEL_TITLE}
                carouselCards={recentElevate}
                carouselTabPath={RECENT_CAROUSELS.ELEVATE.DEFAULT_PATH}
                itemClick={(path) => itemClick(path)}
              />
            ) : (
              <ProductBanner
                heading={PRODUCT_BANNERS.ELEVATE.HEADING}
                bannerImage={PRODUCT_BANNERS.ELEVATE.IMAGE}
                ctaDefaultPath={PRODUCT_BANNERS.ELEVATE.CTA_DEFAULT_PATH}
                ctaList={PRODUCT_BANNERS.ELEVATE.CTA_LIST}
                ctaListDisplayColumn
                ctaClick={(ctaPath) => itemClick(ctaPath)}
              />
            )
          ) : !config.HIDE_FEATURES.elevate && elevateSubExpired ? (
            <ProductBanner
              heading={PRODUCT_BANNERS.ELEVATE.HEADING}
              bannerImage={PRODUCT_BANNERS.ELEVATE.IMAGE}
              ctaDefaultPath={PRODUCT_BANNERS.ELEVATE.CTA_DEFAULT_PATH}
              ctaList={PRODUCT_BANNERS.ELEVATE.CTA_LIST}
              ctaListDisplayColumn
              ctaClickExternal={(ctaPath) => itemClickExternal(ctaPath)}
              ctaClick={(ctaPath) => itemClick(ctaPath)}
            />
          ) : null}
          {/* IF ORG HAS NO SUBS --> SHOW LARGE EMPTY STATE BANNER */}
          {noSubs ? (
            <>
              <ProductBanner
                heading={PRODUCT_BANNERS.PRO_CAST_AGGREGATED.HEADING}
                ctaList={PRODUCT_BANNERS.PRO_CAST_AGGREGATED.CTA_LIST}
                ctaListButtonText={
                  PRODUCT_BANNERS.PRO_CAST_AGGREGATED.CTA_LIST_BUTTON_TEXT
                }
                ctaDefaultPath={
                  PRODUCT_BANNERS.PRO_CAST_AGGREGATED.CTA_DEFAULT_PATH
                }
                ctaClick={(ctaPath) => itemClick(ctaPath)}
              />
              <ProductBannerLarge
                heading={PRODUCT_BANNERS.NO_SUBS_V2.HEADING}
                bannerImage={PRODUCT_BANNERS.NO_SUBS_V2.IMAGE}
                ctaText={PRODUCT_BANNERS.NO_SUBS_V2.CTA_TEXT}
                ctaClick={() =>
                  itemClick(PRODUCT_BANNERS.NO_SUBS_V2.CTA_DEFAULT_PATH)
                }
              />
            </>
          ) : null}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default withRouter(withFlagsConsumer(UserHomepage));
