import React, { useEffect, Suspense, lazy } from 'react';
import globalConfig from 'react-global-configuration';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ApolloConsumer } from 'react-apollo';
import { credentials } from '@shootsta/client-auth';
import {
  AuthorisationConsumer,
  AuthorisationProvider,
  CreditsProvider,
  Error,
  Loader,
  NotFound as PureNotFound,
  Notification,
  NotificationsProvider,
  UploaderProvider,
  GlobalStoreProvider,
  SubscriptionNotification,
  withFlagsConsumer,
  SocketProvider
} from '@shootsta/common-react';
import getAlias from '@shootsta/get-alias';

import ProtectedRoute from './ProtectedRoute';

// Containers & Layouts
import { CoreLayout } from '../layouts';
import { AuthProtected, ErrorBoundary, SSOAuth } from '../common/containers';

// App Pages
import Login from '../common/components/Login';
import MaintenanceScreen from '../common/components/MaintenanceScreen';
import GhostIdentity from '../common/components/Login/containers/GhostIdentity';
import Ghost from '../common/components/Login/components/Ghost';
import Verification from '../common/components/Login/containers/Verification';
import ChooseWorkspace from '../common/components/Login/containers/ChooseWorkspace';
import UserHomepage from '../common/containers/UserHomepage/containers/UserHomepage';
import IdentityLogin, {
  IdentityAuth
} from '../common/components/IdentityLogin';
import IdentityLogout from '../common/components/IdentityLogout';

// Bookings Link
import BookingsLink from '../common/components/BookingsLink';

// PreLoader for big Platform queries
import PreLoaderCore from '../common/components/PreLoaderCore';

// Utils
import { getHomePage, importRetry } from '../utils';

// Constants
import { REQUIRED_PERMISSIONS, AUTH_ROUTE } from '../common/constants';
import config from '../config';

// Migrated UI repositories

const Organisation = lazy(() =>
  // $FlowFixMe
  importRetry(() => import('@core/organisation'))
);

const Scripts = lazy(() => importRetry(() => import('@core/scripts')));

const Inspirations = lazy(() =>
  // $FlowFixMe
  importRetry(() => import('@core/inspirations'))
);

const UserSettings = lazy(() =>
  // $FlowFixMe
  importRetry(() => import('@core/user-settings'))
);
const Traffic = lazy(() => importRetry(() => import('@core/traffic')));
const Elevate = lazy(() => importRetry(() => import('@core/elevate')));
const Reports = lazy(() => importRetry(() => import('@core/reports')));

const SystemAssetLibrary = lazy(() =>
  // $FlowFixMe
  importRetry(() => import('@core/system-asset-library'))
);
const Internal = lazy(() => importRetry(() => import('@core/internal')));
const Storyboards = lazy(() => importRetry(() => import('@core/storyboards')));
const MediaLibrary = lazy(() => importRetry(() => import('@core/assets')));
const VideoTool = lazy(() => importRetry(() => import('@core/video-tool')));
const VideoReview = lazy(() => importRetry(() => import('@core/video-review')));

const ArticleToBrief = lazy(() =>
  // $FlowFixMe
  importRetry(() => import('@core/article-to-brief'))
);

const {
  SCRIPTS,
  STORYBOARDS,
  DAM,
  ORGANISATION,
  INTERNAL,
  VIDEO_TOOL,
  VIDEO_REVIEW,
  INSPIRATIONS,
  USER_SETTINGS,
  CAST,
  ELEVATE,
  CLIENT_STATISTICS_REPORTS,
  MANAGE_ORGANISATION
} = REQUIRED_PERMISSIONS;

const NotFound = () => <PureNotFound />;

const getAuthPublicFallback = (isHideLoginViaIdentity: boolean) =>
  isHideLoginViaIdentity ? <Login /> : <IdentityLogin />;

/**
 * Creates the application route components
 *
 * @return {Element}
 */
function createRoutes({ flags }) {
  useEffect(() => {
    window.launchDarklyClient.waitForInitialization().then(() => {
      const flagValue = window.launchDarklyClient.variation(
        'enable-hubspot-service-hub',
        false
      );

      if (flagValue) {
        return;
      }

      window.Intercom('update');
    });
  }, [window]);

  const { LOGIN_VIA_IDENTITY } = config.HIDE_FEATURES;

  const alias = getAlias();

  const HIDE_FEATURES = globalConfig.get('HIDE_FEATURES');
  const { enableElevateV2 = false, enableBiTool = false } = flags;

  if (flags.enablePlatformMaintenanceMode) {
    return <MaintenanceScreen />;
  }

  if (!alias) {
    return (
      <ErrorBoundary>
        <NotificationsProvider>
          <ApolloConsumer>
            {(client) => (
              <UploaderProvider apiClient={client}>
                <Switch>
                  <Route path="/elevate/public">
                    <Suspense fallback={Loader}>
                      <Elevate />
                    </Suspense>
                  </Route>
                  <Route path="/error" component={Error} />
                  <Route path="/get-workspaces">
                    <Verification client={client} />
                  </Route>
                  <AuthProtected
                    publicFallback={getAuthPublicFallback(LOGIN_VIA_IDENTITY)}
                    makeQuery={client.query}
                  >
                    <Route>
                      <ChooseWorkspace />
                    </Route>
                  </AuthProtected>
                </Switch>
              </UploaderProvider>
            )}
          </ApolloConsumer>
        </NotificationsProvider>
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <Ghost>
        <GlobalStoreProvider>
          <NotificationsProvider>
            <ApolloConsumer>
              {(client) => (
                <Suspense fallback={Loader}>
                  <Switch>
                    <Route path={`/${AUTH_ROUTE}`} component={IdentityAuth} />
                    <Route path="/error" render={() => <Error />} />
                    <Route path="/get-workspaces">
                      <Verification client={client} />
                    </Route>
                    <Route
                      path="/user-settings/(reset-password|invite)"
                      component={UserSettings}
                    />
                    <Route path="/logout" component={IdentityLogout} />
                    <SSOAuth>
                      <AuthProtected
                        publicFallback={getAuthPublicFallback(
                          LOGIN_VIA_IDENTITY
                        )}
                        makeQuery={client.query}
                      >
                        <AuthorisationProvider>
                          <SocketProvider
                            url={
                              process.env.NODE_ENV !== 'production'
                                ? config.socketEndpoint
                                : config.serviceEndpoint
                            }
                          >
                            <GhostIdentity>
                              <UploaderProvider apiClient={client}>
                                <AuthorisationConsumer>
                                  {({
                                    isShootstaEditor,
                                    isShootsta: isShootstaUser,
                                    selectedOrganisation: {
                                      permissions: orgPermissions,
                                      preferences: orgPreferences,
                                      package: organisationPackage
                                    } = {},
                                    organisationMemberships
                                  }) => {
                                    if (
                                      !orgPermissions ||
                                      !orgPermissions.length
                                    ) {
                                      credentials.clear();
                                      return null;
                                    }

                                    // If not Org Admin or any Shootsta user, hide Create Elevate but preserve the rest of the existing global config
                                    if (
                                      !orgPermissions.includes(
                                        MANAGE_ORGANISATION
                                      ) &&
                                      !isShootstaUser
                                    ) {
                                      globalConfig.set(
                                        {
                                          HIDE_FEATURES: {
                                            ...HIDE_FEATURES,
                                            manageElevateTemplates: true
                                          }
                                        },
                                        { assign: true }
                                      );
                                    }

                                    return (
                                      <>
                                        {flags.subscriptionsV22 &&
                                          !flags.disableSubscriptionExpiryNotifications && (
                                            <div style={{ width: '100%' }}>
                                              <SubscriptionNotification
                                                fullWidthStyle
                                              />
                                            </div>
                                          )}
                                        {flags.enablePlatformWideNotification && (
                                          <div style={{ width: '100%' }}>
                                            <Notification
                                              fullWidthStyle
                                              text={
                                                flags.enablePlatformWideNotification
                                              }
                                            />
                                          </div>
                                        )}
                                        {flags.enablePlatformMaintenanceModeNotification && (
                                          <div style={{ width: '100%' }}>
                                            <Notification
                                              fullWidthStyle
                                              backgroundColour="orange"
                                              text={
                                                flags.enablePlatformMaintenanceModeNotification
                                              }
                                            />
                                          </div>
                                        )}
                                        <CreditsProvider>
                                          <CoreLayout
                                            orgPermissions={orgPermissions}
                                            organisationMemberships={
                                              organisationMemberships
                                            }
                                          >
                                            <Suspense fallback={<Loader />}>
                                              <Switch>
                                                <Redirect
                                                  exact
                                                  from="/"
                                                  to={getHomePage(
                                                    orgPreferences,
                                                    organisationPackage,
                                                    isShootstaEditor,
                                                    HIDE_FEATURES
                                                  )}
                                                />
                                                <ProtectedRoute
                                                  path="/scripts"
                                                  component={Scripts}
                                                  permission={SCRIPTS}
                                                />
                                                <ProtectedRoute
                                                  path="/storyboards"
                                                  component={Storyboards}
                                                  permission={STORYBOARDS}
                                                />
                                                <ProtectedRoute
                                                  path="/internal"
                                                  component={Internal}
                                                  permission={INTERNAL}
                                                />
                                                <ProtectedRoute
                                                  path="/organisation"
                                                  component={Organisation}
                                                  permission={ORGANISATION}
                                                />
                                                <ProtectedRoute
                                                  path="/medialibrary"
                                                  component={MediaLibrary}
                                                  permission={DAM}
                                                />
                                                <ProtectedRoute
                                                  path="/review"
                                                  component={VideoReview}
                                                  permission={VIDEO_REVIEW}
                                                />
                                                <ProtectedRoute
                                                  path="/complete/storyboard/:videoId/:storyboardId"
                                                  exact
                                                  component={VideoReview}
                                                  permission={VIDEO_REVIEW}
                                                />
                                                <ProtectedRoute
                                                  path="/(pro|video-tool)"
                                                  component={VideoTool}
                                                  permission={VIDEO_TOOL}
                                                />
                                                {HIDE_FEATURES.cast || (
                                                  <ProtectedRoute
                                                    path="/cast"
                                                    component={VideoTool}
                                                    permission={CAST}
                                                  />
                                                )}
                                                <ProtectedRoute
                                                  path="/traffic"
                                                  component={Traffic}
                                                  permission={INTERNAL}
                                                />
                                                <ProtectedRoute
                                                  path="/user-settings"
                                                  component={UserSettings}
                                                  permission={USER_SETTINGS}
                                                />
                                                <ProtectedRoute
                                                  path="/inspiration"
                                                  component={Inspirations}
                                                  permission={INSPIRATIONS}
                                                />
                                                <Route
                                                  path="/bookings"
                                                  component={BookingsLink}
                                                />
                                                {HIDE_FEATURES.userHomepage || (
                                                  <Route
                                                    path="/home"
                                                    render={() => (
                                                      <UserHomepage
                                                        organisation={
                                                          organisationPackage
                                                        }
                                                      />
                                                    )}
                                                  />
                                                )}
                                                {enableElevateV2 && (
                                                  <ProtectedRoute
                                                    path="/elevate/builder/v2/:videoId"
                                                    exact
                                                    component={Elevate}
                                                    permission={ELEVATE}
                                                  />
                                                )}
                                                {HIDE_FEATURES.manageElevateTemplates || (
                                                  <ProtectedRoute
                                                    path="/elevate/builder"
                                                    component={VideoTool}
                                                    permission={ELEVATE}
                                                  />
                                                )}
                                                <ProtectedRoute
                                                  path="/elevate/complete/:videoId/:outputId"
                                                  exact
                                                  component={VideoTool}
                                                  permission={ELEVATE}
                                                />
                                                {HIDE_FEATURES.manageElevateTemplates || (
                                                  <ProtectedRoute
                                                    path="/elevate/projects"
                                                    component={VideoTool}
                                                    permission={ELEVATE}
                                                  />
                                                )}
                                                {HIDE_FEATURES.manageElevateTemplates || (
                                                  <ProtectedRoute
                                                    path="/elevate/project/:videoId"
                                                    component={VideoTool}
                                                    permission={ELEVATE}
                                                  />
                                                )}
                                                {HIDE_FEATURES.clientFacingReports || (
                                                  <ProtectedRoute
                                                    path="/reports"
                                                    component={Reports}
                                                    permission={
                                                      CLIENT_STATISTICS_REPORTS
                                                    }
                                                  />
                                                )}
                                                <ProtectedRoute
                                                  path="/elevate"
                                                  component={Elevate}
                                                  permission={ELEVATE}
                                                />
                                                <ProtectedRoute
                                                  path="/system-asset-library"
                                                  component={SystemAssetLibrary}
                                                  permission={INTERNAL}
                                                />
                                                {enableBiTool && (
                                                  <Route
                                                    path="/article-to-brief"
                                                    component={ArticleToBrief}
                                                  />
                                                )}
                                                <Route render={NotFound} />
                                              </Switch>
                                              <PreLoaderCore />
                                            </Suspense>
                                          </CoreLayout>
                                        </CreditsProvider>
                                      </>
                                    );
                                  }}
                                </AuthorisationConsumer>
                              </UploaderProvider>
                            </GhostIdentity>
                          </SocketProvider>
                        </AuthorisationProvider>
                      </AuthProtected>
                    </SSOAuth>
                  </Switch>
                </Suspense>
              )}
            </ApolloConsumer>
          </NotificationsProvider>
        </GlobalStoreProvider>
      </Ghost>
    </ErrorBoundary>
  );
}

export default withFlagsConsumer(createRoutes);
