import React from 'react';
import { Icon, Image, MenuButton } from '@shootsta/common-react';
import UserSummary from '../UserSummary';
import './styles/_user-wrapper.scss';

type UserWrapperProps = {
  imageUrl?: string;
  fullName: string;
  isGhosting: boolean;
};

function UserWrapper(props: UserWrapperProps) {
  const { imageUrl = '', fullName, isGhosting } = props;

  return (
    <div className="user-wrapper-container">
      {isGhosting && (
        <Icon
          className="user-wrapper-container__userprofile-box__icon__ghost"
          name="ghost"
          size={24}
          color="monkey"
        />
      )}
      <MenuButton
        id="profile-dropdown"
        customButton={
          <Image
            width={35}
            height={35}
            imageUrl={imageUrl}
            alt="Organisation Profile"
            circular
            placeholderText={fullName}
            placeholderTextSize={12}
          />
        }
      >
        <UserSummary {...props} />
      </MenuButton>
    </div>
  );
}

export default UserWrapper;
