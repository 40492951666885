import React, { Component } from 'react';
import jsonwebtoken from 'jsonwebtoken';
import queryString from 'query-string';
import { credentials } from '@shootsta/client-auth';
import { withRouter } from 'react-router-dom';
import { WelcomeGuest as WelcomeGuestModal } from '@shootsta/common-react';
import type { ReactNode } from 'react';
import type { Location, RouterHistory } from 'react-router-dom';

import When from '../../../components/When';
import Reauthenticate from './Reauthenticate';
import config from '../../../../config';
import {
  redirectToSingleSignOn,
  getSSOLocalStore,
  getIdentityOriginUrl
} from '../../../../utils';
import { handleSignOut, identityReauthenticateRedirect } from '../../../utils';
import { RETURN_TO } from '../../../constants';
import {
  getIsPublicLink,
  getIsPublicUploadLink
} from '../utils/getIsPublicLink';
import LostPage from '../../../components/IdentityLogin/components/LostPage';

const BASE64ID_SESSION_TOKEN_LENGTH = 20;
const MS_PER_SECOND = 1000;

/**
 * PRE_EXPIRE_SECONDS refreshes tokens this many seconds before they actually
 * expire, giving the server a bit of buffer before we potentially send an
 * expired JSONWebToken that could fail auth checks.
 * @type {number}
 */
const PRE_EXPIRE_SECONDS = 30;

const FORGOT_PASSWORD_ROUTE = '/login/forgot';
const NOT_YOU_ROUTE = '/cast/not-you';
const CAST_APP_ROUTE = '/cast';

type Props = {
  publicFallback?: Node;
  children?: Node;
  refreshToken: any;
  login: any;
  location: Location;
  history: RouterHistory;
};

type State = {
  isRefreshing: boolean;
  hasToken: boolean;
  isReauthRequired: boolean;
  reauthenticateError: boolean;
};

class AuthProtected extends Component<Props, State> {
  static defaultProps = {
    publicFallback: null,
    children: null
  };

  constructor(props: Props) {
    super(props);

    const { auth, token } = credentials.get();

    this.state = {
      isRefreshing: true,
      hasToken: Boolean(token),
      reauthenticateError: false,
      isReauthRequired: Boolean(!auth && token)
    };

    credentials.listenToChange(this.handleCredentialsChange);
  }

  async componentDidMount() {
    const { hasToken, isReauthRequired } = this.state;
    const { refreshToken, location } = this.props;
    const { auth, user } = credentials.get();
    const { ssoEnabled } = getSSOLocalStore();
    const { pathname } = location;

    if (isReauthRequired && ssoEnabled) {
      this.setState({ isRefreshing: false });

      return void redirectToSingleSignOn(location.pathname);
    }

    if (!hasToken || !auth || !user || user.isAnonymous || ssoEnabled) {
      return void this.setState({ isRefreshing: false });
    }

    if (pathname === FORGOT_PASSWORD_ROUTE) {
      this.setState({ isRefreshing: false });

      return void this.handleSignOutWithRedirect(FORGOT_PASSWORD_ROUTE);
    }

    const { token, isPublicUpload } = credentials.get();

    if (isPublicUpload && getIsPublicUploadLink(pathname)) {
      this.setState({ isRefreshing: false });

      return void credentials.clear();
    }

    if (token && token.length === BASE64ID_SESSION_TOKEN_LENGTH) {
      await refreshToken();

      return void this.setState({ isRefreshing: false });
    }

    const decodedToken = jsonwebtoken.decode(token);
    const CLOSE_TO_EXPIRY = Math.floor(
      new Date().getTime() / MS_PER_SECOND + PRE_EXPIRE_SECONDS
    );
    if (decodedToken.exp > CLOSE_TO_EXPIRY) {
      // If token within 30s (configurable) of expiry, no need to refresh it
      return void this.setState({ isRefreshing: false });
    }
    this.setState({ isRefreshing: false });

    await refreshToken();
  }

  componentDidUpdate() {
    const {
      location: { pathname }
    } = this.props;

    if (pathname === NOT_YOU_ROUTE) {
      return void this.handleSignOutWithRedirect(CAST_APP_ROUTE);
    }
  }

  handleCredentialsChange = (usercrdls?: {
    auth: boolean | null | undefined;
    token: string | null | undefined;
  }) => {
    const {
      location: { pathname }
    } = this.props;

    if (getIsPublicUploadLink(pathname)) {
      return;
    }

    if (!usercrdls) {
      return void this.setState({ hasToken: false, isReauthRequired: false });
    }

    const { auth, token } = usercrdls;

    this.setState(
      {
        hasToken: Boolean(token),
        isReauthRequired: Boolean(!auth && token)
      },
      () => {
        const { isReauthRequired } = this.state;
        const { location } = this.props;
        const { ssoEnabled } = getSSOLocalStore();

        if (
          isReauthRequired &&
          !ssoEnabled &&
          !config.HIDE_FEATURES.LOGIN_VIA_IDENTITY
        ) {
          try {
            identityReauthenticateRedirect();
          } catch (e: any) {
            this.setState({ reauthenticateError: true });
          }
        }

        if (!isReauthRequired || !ssoEnabled) {
          return;
        }

        redirectToSingleSignOn(location.pathname);
      }
    );
  };

  handleSignOutWithRedirect(redirectPath: string) {
    const { history } = this.props;
    const { HIDE_FEATURES } = config;

    return void handleSignOut({
      history,
      loginViaIdentity: !HIDE_FEATURES.LOGIN_VIA_IDENTITY,
      redirectPath
    });
  }

  render() {
    const { publicFallback, children, login, location, history } = this.props;
    const { reauthenticateError, isRefreshing } = this.state;

    if (isRefreshing) {
      return null;
    }

    const parsedQueryParams: any = queryString.parse(location.search);
    const { redirecting, p: world } = parsedQueryParams;

    const { pathname } = location;

    if (reauthenticateError) {
      return <LostPage />;
    }

    if (redirecting) {
      return null;
    }

    const { hasToken, isReauthRequired } = this.state;

    if (!getIsPublicUploadLink(pathname)) {
      const { isPublicUpload } = credentials.get();

      if (isPublicUpload) {
        credentials.clear();

        return <WelcomeGuestModal visible />;
      }
    }

    if (!hasToken && world) {
      if (getIsPublicLink(pathname)) {
        return <>{children}</>;
      }

      if (!config.HIDE_FEATURES.LOGIN_VIA_IDENTITY) {
        const returnToMinusProtocol = window.location.href.replace(
          /^https?:\/\//,
          ''
        );
        const newQueryString = new URLSearchParams({
          [RETURN_TO]: returnToMinusProtocol
        });

        window.location.href = `${getIdentityOriginUrl()}/guest?${newQueryString.toString()}`;

        return null;
      }

      return <WelcomeGuestModal visible />;
    }

    return (
      <When condition={hasToken} failWith={publicFallback}>
        {isReauthRequired && config.HIDE_FEATURES.LOGIN_VIA_IDENTITY ? (
          <Reauthenticate visible history={history} login={login} />
        ) : (
          children
        )}
      </When>
    );
  }
}

export default withRouter(AuthProtected);
