import gql from 'graphql-tag';
import { credentials } from '@shootsta/client-auth';

const getFilteredOutputsQuery = gql`
  query GetFilteredOutputs(
    $filter: OutputFilterInput
    $limit: Int
    $offset: Int
    $sortBy: String
    $organisationId: String!
    $product: ProductType
  ) {
    getFilteredOutputs(
      filter: $filter
      limit: $limit
      offset: $offset
      sortBy: $sortBy
      organisationId: $organisationId
      product: $product
    ) {
      count
      outputs {
        outputId
        videoId
        readableId
        status {
          name
        }
        name
        versions {
          versionNumber
          dueDate
          versionId
          assetId
        }
        video {
          organisationId
          videoProjectId
          submittedAt
          clips {
            assetId
          }
          assetIds
          name
          product
          createdAt
          creator {
            userId
            firstName
            lastName
            imageUrl
          }
        }
      }
    }
  }
`;

function normaliseUserFilter(filter: any) {
  return filter.USER && filter.USER.includes('ALL')
    ? { ...filter, USER: void 0 }
    : { ...filter, USER: [credentials.get().user.userId] };
}

const getFilteredOutputs = () => ({
  query: getFilteredOutputsQuery,
  module: 'video',
  ignoreCache: true,
  buildVariables: ({
    limit,
    offset,
    sortBy,
    product,
    organisationId,
    ...filter
  }: any) => ({
    filter: normaliseUserFilter(filter),
    limit,
    offset,
    sortBy,
    organisationId,
    product
  })
});

export default getFilteredOutputs;
