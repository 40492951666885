import { Button, Carousel, Text } from '@shootsta/common-react';
import _cloneDeep from 'lodash.clonedeep';
import React, { useState } from 'react';
import cx from 'classnames';
import CarouselListComponent from './CarouselListComponent';
import '../styles/_additional-field-carousel.scss';

type Field = {
  fieldId: string;
  label: string;
  isSelected: boolean;
  assetId?: string;
};

interface AdditionalFieldCarouselProps {
  fields: Array<Field>;
  onSelect: any;
  label: string;
  description: string;
  assetsMap: Map<string, any>;
  disabled: boolean;
  type: 'RADIO' | 'CHECKBOX';
  isMandatory: boolean;
  isDrawerOpen: boolean;
}

function AdditionalFieldCarousel({
  fields,
  assetsMap,
  onSelect,
  description,
  label,
  type,
  disabled,
  isMandatory,
  isDrawerOpen
}: AdditionalFieldCarouselProps) {
  const [latestFields, setLatestFields] = useState(fields);
  const [currentStep, setCurrentStep] = useState(0);

  const handleSelect = (fieldId: string) => {
    const newFields = _cloneDeep(latestFields).map((field) => {
      if (type === 'RADIO') {
        return { ...field, isSelected: field.fieldId === fieldId };
      }
      return field.fieldId === fieldId
        ? { ...field, isSelected: !field.isSelected }
        : field;
    });
    setLatestFields(newFields);
    onSelect(newFields);
  };

  const carousalLength = isDrawerOpen ? 3 : 4;

  const handleNext = () => {
    if (currentStep + carousalLength >= latestFields?.length) return;

    setCurrentStep(currentStep + carousalLength);
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - carousalLength);
    }
  };

  return (
    <div
      className={cx('additional-field', {
        'additional-field__carousal-width':
          latestFields?.length <= carousalLength
      })}
    >
      <div className="additional-field__field">
        <div className="additional-field__field__input">
          <Text
            required={isMandatory}
            className="additional-field__field__title"
          >
            {label}
          </Text>
          <Text className="additional-field__field__subtitle">
            {description}
            &nbsp;
            {`(${latestFields?.length} option${latestFields?.length > 1 && 's'})`}
          </Text>
        </div>
        {(latestFields?.length ?? 0) > carousalLength && (
          <>
            <div>
              <Button
                onClick={handleBack}
                disabled={currentStep === 0}
                type="TERTIARY"
                prefix="chevronleft"
                className="additional-field__field__arrow-button"
              />
            </div>
            <div>
              <Button
                onClick={handleNext}
                disabled={currentStep + carousalLength >= latestFields?.length}
                type="TERTIARY"
                prefix="chevronright"
                className="additional-field__field__arrow-button"
              />
            </div>
          </>
        )}
      </div>

      <Carousel
        slidesToShow={carousalLength}
        slideToGo={currentStep}
        config={{
          arrows: false,
          dots: true,
          dotsClass: 'carousel-dots'
        }}
        itemWidth={222}
        hint="You can do something"
      >
        {latestFields?.map((field) => (
          <CarouselListComponent
            field={field}
            assetsMap={assetsMap}
            handleSelect={handleSelect}
            type={type}
            disabled={disabled}
          />
        ))}
      </Carousel>
    </div>
  );
}

export default AdditionalFieldCarousel;
