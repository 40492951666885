import React from 'react';
import { RequestProvider, requests } from '@shootsta/common-react';
import CompleteSetupComponent from '../components/CompleteSetup';
import getUserApi from '../api/getUser';
import getUserOrganisationsApi from '../api/getUserOrganisations';

type CompleteSetupProps = {
  userId: string;
};

const { updateUserMutation } = requests;

const CompleteSetup = ({ userId }: CompleteSetupProps) => (
  <RequestProvider
    requests={{
      getUser: getUserApi(userId),
      updateUser: updateUserMutation(),
      getUserOrganisations: getUserOrganisationsApi()
    }}
  >
    {({ getUser, updateUser, getUserOrganisations }) => (
      <CompleteSetupComponent
        getUser={getUser}
        updateUser={updateUser}
        getUserOrganisations={getUserOrganisations}
      />
    )}
  </RequestProvider>
);

export default CompleteSetup;
