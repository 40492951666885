import React, { useEffect, useRef, useState } from 'react';
import {
  FloatingWindow,
  Wizard,
  Icon,
  Text,
  notify
} from '@shootsta/common-react';
import '../styles/completeSetup.scss';
import SetupWizardChild from './SetupWizardChild';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

type UpdateUserInputType = {
  department?: string;
  industry?: string;
  jobTitle?: string;
  ageRange?: string;
  topics?: Array<string>;
  contentTypes?: Array<string>;
  biOnboardingComplete?: boolean;
};

type CompleteSetupProps = {
  getUser: any;
  updateUser: any;
  getUserOrganisations: any;
};

const CompleteSetup = ({
  getUser,
  updateUser,
  getUserOrganisations
}: CompleteSetupProps) => {
  const [state, setState] = useState({
    userId: undefined,
    ageRange: undefined,
    role: undefined,
    department: undefined,
    industry: '',
    topics: [],
    contentTypes: [],
    setupStatus: false,
    isFloatingVisible: false
  });

  useEffect(() => {
    const loadUser = async () => {
      const {
        data: { getUser: user }
      } = await getUser();
      const {
        data: { getUserOrganisations: orgs }
      } = await getUserOrganisations({ email: user.email });
      const companies = orgs.map((w) => w.name);
      setState((s) => ({
        ...s,
        userId: user.userId,
        ageRange: user.ageRange,
        role:
          user.jobTitle?.length && companies.includes(user.jobTitle)
            ? undefined
            : user.jobTitle,
        department: user.department,
        industry: user.industry,
        topics: user.topics ?? [],
        contentTypes: user.contentTypes ?? [],
        isFloatingVisible: !user.biOnboardingComplete
      }));
    };
    loadUser();
  }, []);

  const setAgeRange = (ageRange: any) => setState((s) => ({ ...s, ageRange }));
  const setRole = (role: any) => setState((s) => ({ ...s, role }));
  const setDepartment = (department: any) =>
    setState((s) => ({ ...s, department }));
  const setindustry = (industry: any) => setState((s) => ({ ...s, industry }));
  const setTopics = (topics: any) => setState((s) => ({ ...s, topics }));
  const setContentTypes = (contentTypes: any) =>
    setState((s) => ({ ...s, contentTypes }));
  const setSetupStatus = (setupStatus: boolean) =>
    setState((s) => ({ ...s, setupStatus }));
  const setIsFloatingVisible = (isFloatingVisible: boolean) =>
    setState((s) => ({ ...s, isFloatingVisible }));

  const floatigWindowRef = useRef(null);

  const cleanup = () => {
    const hubspotContainer = document.getElementById(
      'hubspot-messages-iframe-container'
    );
    if (hubspotContainer) {
      hubspotContainer.classList.remove('hubspot-bitool');
      hubspotContainer.classList.remove('hubspot-bitool__expanded');
    }
  };

  useEffect(() => {
    return cleanup;
  }, []);

  const close = (delay = 500) => {
    floatigWindowRef.current?.toggleExpand();
    setTimeout(() => {
      setIsFloatingVisible(false);
      cleanup();
    }, delay);
  };

  const onSkipForNow = () => {
    localStorage.setItem(
      'biOnboardingSkipUntil',
      `${Date.now() + 1000 * 60 * 60 * 24}`
    );
    close();
  };

  const biOnboardingSkipUntil = parseInt(
    localStorage.getItem('biOnboardingSkipUntil')
  );

  const onChangeWizardStage = async (goToNextStage = null) => {
    const input: UpdateUserInputType = {
      department: state.department,
      industry: state.industry,
      jobTitle: state.role,
      ageRange: state.ageRange,
      topics: state.topics,
      contentTypes: state.contentTypes,
      biOnboardingComplete: !goToNextStage
    };
    try {
      await updateUser({ userId: state.userId, input });

      if (goToNextStage) {
        goToNextStage();
        return;
      }
      setSetupStatus(true);

      close(2000);
    } catch (e: any) {
      notify(e.message ?? 'Error updating - data not saved');
    }
  };

  const addHubspotClasses = (expanded: any) => {
    const hubspotContainer = document.getElementById(
      'hubspot-messages-iframe-container'
    );

    if (!hubspotContainer) {
      return null;
    }

    hubspotContainer.classList.add('hubspot-bitool');

    if (expanded) {
      hubspotContainer.classList.add('hubspot-bitool__expanded');
    } else {
      hubspotContainer.classList.remove('hubspot-bitool__expanded');
    }

    return hubspotContainer;
  };

  const header = ({ isExpanded }) => {
    if (isExpanded) return <div></div>;
    return (
      <div className="floating-window-container__header">
        {state.setupStatus ? 'Setup Completed' : 'Complete your setup'}
      </div>
    );
  };

  const content = () => {
    return (
      <div className="content-container">
        <div className="content-container__heading">
          <Text heading3 noMargin className="content-container__heading__title">
            Complete your setup
          </Text>
          <Text
            noMargin="bottom"
            placeholder
            className="content-container__heading__subtitle"
          >
            We’ll use this to help personalise your experience
          </Text>
        </div>
        <Wizard
          isV2Design
          progressBarPosition="left"
          className="content-container__wizard"
          hideBack
        >
          <SetupWizardChild
            buttonText="Next"
            buttonDisabled={
              !state.topics || state.topics.length === 0 || !state.industry
            }
            onClick={(goToNextWizardStage) =>
              onChangeWizardStage(goToNextWizardStage)
            }
            onSkipForNow={onSkipForNow}
          >
            <Step1
              topics={state.topics}
              setTopics={setTopics}
              industry={state.industry}
              setindustry={setindustry}
            />
          </SetupWizardChild>
          <SetupWizardChild
            buttonText="Next"
            buttonDisabled={!state.department || !state.role || !state.ageRange}
            onClick={(goToNextWizardStage) =>
              onChangeWizardStage(goToNextWizardStage)
            }
            onSkipForNow={onSkipForNow}
          >
            <Step2
              department={state.department}
              setDepartment={setDepartment}
              role={state.role}
              setRole={setRole}
              ageRange={state.ageRange}
              setAgeRange={setAgeRange}
            />
          </SetupWizardChild>
          <SetupWizardChild
            buttonText="Finish Setup"
            buttonDisabled={
              !state.contentTypes || state.contentTypes.length === 0
            }
            onClick={() => onChangeWizardStage()}
            onSkipForNow={onSkipForNow}
          >
            <Step3
              contentTypes={state.contentTypes}
              setContentTypes={setContentTypes}
            />
          </SetupWizardChild>
        </Wizard>
      </div>
    );
  };
  return (
    <div>
      {state.isFloatingVisible &&
        (isNaN(biOnboardingSkipUntil) ||
          Date.now() > biOnboardingSkipUntil) && (
          <FloatingWindow
            initiallyOpen={state.isFloatingVisible}
            classNames="floating-window-container"
            ref={floatigWindowRef}
            position="right"
            drag={false}
            header={({ isExpanded }) => {
              addHubspotClasses(isExpanded);
              return header({
                isExpanded
              });
            }}
            content={content()}
            toggleIcon={
              <Icon
                name={state.setupStatus ? 'success' : 'chevronup'}
                color="white"
                size={14}
              />
            }
          />
        )}
    </div>
  );
};

export default CompleteSetup;
