import { gql } from 'apollo-boost';

const GET_USER_TEAM_QUERY = gql`
  query getUserTeam(
    $userId: String!
    $organisationId: String!
    $type: TeamType!
  ) {
    getUserTeam(userId: $userId, organisationId: $organisationId, type: $type) {
      members {
        user {
          userId
          isAvailable
        }
      }
    }
  }
`;

export default ({ userId, organisationId, type }: any) => ({
  query: GET_USER_TEAM_QUERY,
  module: 'users',
  ignoreCache: true,
  buildVariables: () => ({ userId, organisationId, type })
});
