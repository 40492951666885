import React, { memo } from 'react';
import {
  Text,
  Image,
  Dropdown,
  requiresPermission,
  hasPermission
} from '@shootsta/common-react';
import type { RouterHistory as History } from 'react-router-dom';
import UserAvailability from './UserAvailability';
import OutputsList from './OutputsList';
import '../styles/_user-summary.scss';

const OutputsListComponent = requiresPermission({
  ComponentToRender: OutputsList,
  requiredPermission: 'INTERNAL'
});

const dropdowns = {
  manageProfile: { label: 'Manage Profile', prefix: 'user' },
  stopGhosting: { label: 'Stop Ghosting', prefix: 'ghost' },
  changeWorkspace: { label: 'Change Workspace', prefix: 'organisation' },
  signOut: { label: 'Sign Out', prefix: 'exit' }
} as const;

type UserSummaryProps = {
  userId: string;
  imageUrl?: string;
  isGhosting: boolean;
  handleSignOut: any;
  handleStopGhosting: any;
  toggleAdminMode: any;
  adminMode: boolean;
  showSwitchOrganisationModal: any;
  selectedOrganisation: any;
  organisationMemberships: Array<any>;
  history: History;
  isShootstaEditor: boolean;
};

function goToUrl(history: any) {
  return ({ value }) => {
    history.push(value);
  };
}

function UserSummary(props: UserSummaryProps) {
  const {
    userId,
    history,
    adminMode,
    isGhosting,
    handleSignOut,
    toggleAdminMode,
    isShootstaEditor,
    handleStopGhosting,
    selectedOrganisation,
    organisationMemberships,
    showSwitchOrganisationModal
  } = props;

  const {
    imageUrl: orgImageUrl,
    name: orgName,
    permissions
  } = selectedOrganisation;
  const hasAnotherWorkspace = organisationMemberships.length > 1;
  const userEditLink = `/user-settings/edit/${userId}`;
  const hasOrgPermission = hasPermission('ORGANISATION', permissions);
  const pushUrl = goToUrl(history);

  return (
    <div className="user-summary">
      <div className="user-summary__container">
        <Dropdown.Label
          prefixSize={20}
          data={{ ...dropdowns.manageProfile, value: userEditLink }}
          selectOption={pushUrl}
        />
        {isGhosting && (
          <Dropdown.Label
            prefixSize={20}
            data={{ ...dropdowns.stopGhosting, value: userEditLink }}
            selectOption={handleStopGhosting}
          />
        )}
        <hr />
        <Dropdown.Label
          prefixSize={20}
          disabled={!hasOrgPermission}
          data={{
            label: (
              <div className="user-summary__organisation-info">
                <Image
                  width="20px"
                  height="20px"
                  imageUrl={orgImageUrl}
                  backgroundSize="cover"
                  placeholderText={orgName}
                  rounded
                  placeholderTextSize={10}
                />
                <Text
                  noMargin
                  className="user-summary__organisation-info__description__name"
                >
                  {orgName}
                </Text>
              </div>
            ),
            value: '/organisation/'
          }}
          selectOption={pushUrl}
        />
        {toggleAdminMode && (
          <Dropdown.Label
            prefixSize={20}
            data={{
              label: adminMode ? 'Exit Administration' : 'Administration',
              prefix: 'intaccess'
            }}
            selectOption={toggleAdminMode}
          />
        )}
        {isShootstaEditor ? (
          <>
            <UserAvailability selectedOrganisation={selectedOrganisation} />
            <OutputsListComponent permissions={permissions} />
          </>
        ) : null}
        {hasAnotherWorkspace && (
          <>
            <hr />
            <Dropdown.Label
              prefixSize={20}
              data={dropdowns.changeWorkspace}
              selectOption={showSwitchOrganisationModal}
            />
          </>
        )}
        <hr />
        <Dropdown.Label
          prefixSize={20}
          data={dropdowns.signOut}
          selectOption={handleSignOut}
        />
      </div>
    </div>
  );
}

export default memo<UserSummaryProps>(UserSummary);
