import { gql } from 'apollo-boost';

const GET_ORGANISATIONS_FROM_USER_CODE = gql`
  query getOrgsForUserCode($email: String!, $code: String!) {
    getOrgsForUserCode(email: $email, code: $code) {
      organisationId
      name
      alias
      imageUrl
    }
  }
`;

export default () => ({
  mutation: GET_ORGANISATIONS_FROM_USER_CODE,
  module: 'users',
  buildVariables: ({ email, code }: { email: string; code: string }) => ({
    email,
    code
  })
});
