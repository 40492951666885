import {
  AuthorisationConsumer,
  CreditsConsumer,
  RequestProvider
} from '@shootsta/common-react';
import TopupCreditsModalComponent from '../components/TopupCreditsModal';
import getContractsApi from '../api/getContracts';

interface TopupCreditsModal {
  visible: boolean;
  onClose: () => void;
}

function TopupCreditsModal({ visible, onClose }: TopupCreditsModal) {
  return (
    <AuthorisationConsumer>
      {({ selectedOrganisation: { imageUrl, name, organisationId } }) => (
        <CreditsConsumer>
          {({ state: { contractItemBalance } }) => (
            <RequestProvider
              requests={{ getContracts: getContractsApi(organisationId) }}
            >
              {({ getContracts }) => (
                <TopupCreditsModalComponent
                  orgName={name}
                  orgImageUrl={imageUrl}
                  visible={visible}
                  onClose={onClose}
                  contractItemBalance={contractItemBalance}
                  getContracts={getContracts}
                />
              )}
            </RequestProvider>
          )}
        </CreditsConsumer>
      )}
    </AuthorisationConsumer>
  );
}

export default TopupCreditsModal;
