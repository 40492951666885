import React from 'react';
import jwt from 'jsonwebtoken';
import queryString from 'query-string';
import { Error as ErrorComponent } from '@shootsta/common-react';
import { authLocalStore } from '@shootsta/client-auth';
import type { ReactNode } from 'react';
import config from '../../../../config';

type GhostParams = {
  token: string;
  user: string;
  original?: string;
  origURL?: string;
  targetURL: string;
  clearGhost?: boolean;
};

export default function Ghost({ children }: { children: Node }) {
  const urlParams = queryString.parse(window.location.search);

  if (!config.HIDE_FEATURES.LOGIN_VIA_IDENTITY) {
    return children;
  }

  // url params value can be string, array or null
  if (!urlParams.ghost || Array.isArray(urlParams.ghost)) {
    return children;
  }

  try {
    const {
      token,
      user,
      original,
      origURL,
      targetURL,
      clearGhost
    }: GhostParams = JSON.parse(atob(urlParams.ghost));

    const decodedToken = jwt.decode(token);

    if (!decodedToken) {
      return <ErrorComponent />;
    }

    const data = {
      auth: true,
      ghost: !clearGhost,
      token,
      user,
      original,
      origURL
    } as const;

    authLocalStore.save(data);

    window.location.replace(targetURL);

    return null;
  } catch (e: any) {
    return <ErrorComponent />;
  }
}
