import React from 'react';
import { SizeMe } from 'react-sizeme';
import {
  withPublicLink,
  AuthorisationConsumer,
  CreditsConsumer
} from '@shootsta/common-react';
import { ApolloConsumer } from 'react-apollo';
import CoreLayoutComponent from '../components/CoreLayout';

function CoreLayout(props: any) {
  return (
    <ApolloConsumer>
      {(apolloClient) => (
        <AuthorisationConsumer>
          {({
            selectedOrganisation: {
              imageUrl,
              name,
              package: organisationPackage,
              organisationId,
              preferences
            }
          }) => (
            <CreditsConsumer>
              {({ state: { canCreateOrSubmitProject } }) => (
                <SizeMe>
                  {({ size: { width } = { width: 0 } }) => (
                    <CoreLayoutComponent
                      shootstaAI={preferences?.shootstaAI || false}
                      logoUrl={imageUrl}
                      organisationName={name}
                      organisationPackage={organisationPackage}
                      apolloClient={apolloClient}
                      organisationId={organisationId}
                      width={width}
                      canCreateOrSubmitProject={canCreateOrSubmitProject}
                      {...props}
                    />
                  )}
                </SizeMe>
              )}
            </CreditsConsumer>
          )}
        </AuthorisationConsumer>
      )}
    </ApolloConsumer>
  );
}
export default withPublicLink(CoreLayout);
