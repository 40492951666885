import gql from 'graphql-tag';

const getContractsQuery = gql`
  query getContracts(
    $organisationId: String!
    $product: String
    $status: String
  ) {
    getContracts(
      organisationId: $organisationId
      product: $product
      status: $status
    ) {
      Payload
    }
  }
`;

export default (organisationId: string) => ({
  query: getContractsQuery,
  module: 'organisations',
  ignoreCache: true,
  buildVariables: ({
    product,
    status
  }: {
    organisationId: string;
    product?: string;
    status?: string;
  }) => ({
    organisationId,
    product,
    status
  })
});
