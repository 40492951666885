import React, { useState, useEffect } from 'react';
import { Loader, NotFound } from '@shootsta/common-react';
import getOrgAlias from '@shootsta/get-alias';
import { getIdentityUrl } from '../utils';
import { RETURN_TO, TTL } from '../../../constants';
import LostPage from './LostPage';
import { getBaseDomain } from '../../../../utils';
import { canReauthenticate, getRedirectTTL } from '../../../utils';

type IdentityLoginProps = {
  getOrganisationNameFromAlias: any;
};

function checkData(dataKey: string) {
  return (result: any) => {
    if (
      !result ||
      result.error ||
      !result.data ||
      result.data[dataKey] == null
    ) {
      return Promise.reject(result.error || `${dataKey} is nil`);
    }
    return result.data[dataKey];
  };
}

export default function IdentityLogin({
  getOrganisationNameFromAlias
}: IdentityLoginProps) {
  const [checkingOrgName, setCheckingOrgName] = useState(true);
  const [orgFound, setOrgFound] = useState(false);

  if (!canReauthenticate()) {
    return <LostPage />;
  }

  const baseDomain = getBaseDomain();
  const orgAlias = getOrgAlias();

  const returnToMinusProtocol = window.location.href.replace(
    /^https?:\/\//,
    ''
  );
  const queryParams = new URLSearchParams({
    [RETURN_TO]: returnToMinusProtocol,
    [TTL]: getRedirectTTL() || '1'
  });

  if (!orgAlias) {
    window.location.href = new URL(
      `?${queryParams.toString()}`,
      getIdentityUrl().href
    ).href;
  }

  useEffect(() => {
    if (!orgAlias) {
      return;
    }

    getOrganisationNameFromAlias({ alias: orgAlias })
      .then(checkData('getOrganisationNameFromAlias'))
      .then(() => setOrgFound(true))
      .catch((error) => {
        console.error('Error fetching organisation name:', error);
      })
      .finally(() => setCheckingOrgName(false));
  }, []);

  if (checkingOrgName) {
    return <Loader />;
  }

  if (!orgFound) {
    return <NotFound homeUrl={`${window.location.protocol}//${baseDomain}`} />;
  }

  window.location.href = new URL(
    `?${queryParams.toString()}`,
    getIdentityUrl().href
  ).href;

  return <></>;
}
