import React, { useMemo } from 'react';
import { Input, Dropdown } from '@shootsta/common-react';
import { AGE_RANGES, USER_DEPARTMENT } from '@shootsta/common-domain-data';

type Step2Props = {
  department?: string;
  setDepartment: any;
  role?: string;
  setRole: any;
  ageRange?: string;
  setAgeRange: any;
};

const Step2 = ({
  department,
  setDepartment,
  role,
  setRole,
  ageRange,
  setAgeRange
}: Step2Props) => {
  const ageRangeOptions = useMemo(() =>
    AGE_RANGES.map((i) => ({ label: i, value: i }))
  );
  const userDepartmentOptions = useMemo(() =>
    USER_DEPARTMENT.map((i) => ({ label: i, value: i }))
  );
  return (
    <div className="wizard-body__kyc-content">
      <Dropdown
        type="BUTTON"
        label="What kind of work do you do?"
        className="wizard-body__kyc-content__work-dropdown"
        placeholder="Select department"
        options={userDepartmentOptions}
        isFullWidth
        value={department}
        onChange={(o) => setDepartment(o.value)}
      />
      <Input
        className="wizard-body__kyc-content"
        isFullWidth
        label="Role"
        placeholder="Indicate role"
        defaultValue={role}
        value={role}
        onChange={(e) => setRole(e.target.value)}
      />
      <Dropdown
        type="BUTTON"
        className="wizard-body__kyc-content__age-dropdown"
        options={ageRangeOptions}
        isFullWidth
        label="Age"
        placeholder="Select an age band"
        value={ageRange}
        onChange={(o) => setAgeRange(o.value)}
      />
    </div>
  );
};

export default Step2;
