import React from 'react';
import { credentials } from '@shootsta/client-auth';
import {
  RequestProvider,
  GlobalStoreConsumer,
  AuthorisationConsumer
} from '@shootsta/common-react';
import getFilteredVideosApi from '../api/getFilteredVideos';
import PreLoaderComponent from '../components/PreLoaderCore';

function PreLoaderContainer() {
  const { user, auth } = credentials.get();

  if (!user || !auth || (auth && user.isAnonymous)) {
    return null;
  }

  return (
    <AuthorisationConsumer requiresOrganisation>
      {({ selectedOrganisation: { organisationId } }) => (
        <RequestProvider
          requests={{
            getFilteredVideos: getFilteredVideosApi(organisationId)
          }}
        >
          {(requests) => (
            <GlobalStoreConsumer>
              {(globalStore) => (
                <PreLoaderComponent
                  requests={requests}
                  globalStore={globalStore}
                />
              )}
            </GlobalStoreConsumer>
          )}
        </RequestProvider>
      )}
    </AuthorisationConsumer>
  );
}

export default PreLoaderContainer;
