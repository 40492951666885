import gql from 'graphql-tag';
import { credentials } from '@shootsta/client-auth';
import _flow from 'lodash.flow';

const getFilteredVideosQuery = gql`
  query GetFilteredVideos(
    $filter: OutputFilterInput
    $limit: Int
    $offset: Int
    $sortBy: String
    $organisationId: String
    $product: ProductType
  ) {
    getFilteredVideos(
      filter: $filter
      limit: $limit
      offset: $offset
      sortBy: $sortBy
      organisationId: $organisationId
      product: $product
    ) {
      count
      videos {
        videoId
        organisationId
        videoProjectId
        readableId
        submittedAt
        requestedExtras
        appliedExtras
        clips {
          assetId
        }
        name
        creator {
          userId
          firstName
          lastName
          imageUrl
        }
        outputs {
          outputId
          videoId
          readableId
          status {
            name
          }
          aspectRatio
          isMaster
          onHoldDate
          onHoldReason
          name
          description
          tags
          latestVersion
          versions {
            versionNumber
            dueDate
            versionId
            assetId
          }
        }
        product
      }
    }
  }
`;

function normaliseUserFilter(filter: any) {
  if (
    filter.USER &&
    filter.USER.includes('COLLABORATING_ON') &&
    !filter.USER.includes('ALL')
  ) {
    return {
      ...filter,
      USER: ['COLLABORATING_ON', credentials.get().user.userId]
    };
  }

  if (
    filter.USER &&
    filter.USER.includes('ME') &&
    !filter.USER.includes('ALL')
  ) {
    return {
      ...filter,
      USER: [credentials.get().user.userId]
    };
  }

  if (filter.USER && filter.USER.includes('ALL') && filter.USER.length > 1) {
    const trimmedUser = [...filter.USER];
    trimmedUser.splice(trimmedUser.indexOf('ALL'), 1);

    return {
      ...filter,
      USER: trimmedUser
    };
  }

  return { ...filter, USER: void 0 };
}

function normaliseDateFilter(filter: any) {
  if (filter.DATE_CREATED && filter.DATE_CREATED.length === 2) {
    return { ...filter };
  }

  return { ...filter, DATE_CREATED: void 0 };
}

function normaliseStatusFilter(filter: any) {
  return filter.STATUS && filter.STATUS.includes('ALL')
    ? { ...filter, STATUS: void 0 }
    : filter;
}

function normaliseArchiveFilter(filter: any) {
  return {
    ...filter,
    isArchived: filter && filter.isArchived && filter.isArchived[0]
  };
}

function normaliseFilter(filter: any) {
  return _flow([
    normaliseUserFilter,
    normaliseDateFilter,
    normaliseStatusFilter,
    normaliseArchiveFilter
  ])(filter);
}

const getFilteredVideos = (organisationId: string) => ({
  query: getFilteredVideosQuery,
  module: 'video',
  ignoreCache: true,
  buildVariables: ({ limit, offset, sortBy, product, ...filter }: any) => ({
    filter: normaliseFilter(filter),
    limit,
    offset,
    sortBy,
    organisationId,
    product
  })
});

export default getFilteredVideos;
