import React from 'react';
import { RequestProvider, withPublicLink } from '@shootsta/common-react';
import SSOAuthComponent from '../components/SSOAuth';
import { getOrgSSOConfigApi } from '../api';

function SSOAuth(props: any) {
  return (
    <RequestProvider
      requests={{
        getOrgSSOConfig: getOrgSSOConfigApi()
      }}
    >
      {({ getOrgSSOConfig }) => (
        <SSOAuthComponent getOrgSSOConfig={getOrgSSOConfig} {...props} />
      )}
    </RequestProvider>
  );
}

export default withPublicLink(SSOAuth);
