import React from 'react';
import { RequestProvider } from '@shootsta/common-react';
import type { ReactNode } from 'react';
import GhostIdentityComponent from '../components/GhostIdentity';
import { ghostStartApi } from '../../../api';

type GhostProps = {
  children: Node;
};

export default function GhostIdentity({ children }: GhostProps) {
  return (
    <RequestProvider requests={{ ghostStart: ghostStartApi() }}>
      {(requests) => (
        <GhostIdentityComponent {...requests}>
          {children}
        </GhostIdentityComponent>
      )}
    </RequestProvider>
  );
}
