import { SHOOTSTA_ALIAS, SHOOTSTA_OLD_ALIASES } from '../../../../constants';

const getUpdatedLink = (link: string) => {
  const linkParts = link.match(
    /(https?:\/\/)?((.+)(\.))?(.+\.shootsta\.com|localhost)(:\d+)?(.*)/
  );

  if (!linkParts) {
    return link;
  }

  const [, protocol, , alias, dot, platform, port, path] = linkParts;

  const fixedAlias = SHOOTSTA_OLD_ALIASES.includes(alias)
    ? SHOOTSTA_ALIAS
    : alias;

  return `${protocol || ''}${fixedAlias || ''}${alias ? dot : ''}${platform}${port || ''}${path}`;
};

export default getUpdatedLink;
